<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <base-material-card color="blue">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" color="white">
              {{ $phraser.set('subclients') }}
            </div>
          </template>
        </base-material-card>
        <v-card class="table-card">
          <v-card-title>
            <v-row class="d-flex justify-space-between align-center mb-2 px-2">
              <v-text-field
                v-model="search"
                class="d-flex table-search"
                append-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                hide-details
              />
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="clients"
            :search="search"
            :class="clickable ? 'clickable' : ''"
            @click:row="handleClick"
          >
            <template v-slot:item.updated_at="{ item }">
              {{ formatTime(item.updated_at) }}
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ formatTime(item.created_at) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ClientDetails',

  data: () => ({
    icon: 'md-account-tie',
    color: 'blue',
    clickable: true,
    loading: false,
    search: '',
    headers: [],
  }),

  computed: {
    isSelf () {
      return this.client.code === this.$store.getters['clients/self'].code
    },
    client () {
      return this.$store.getters['clients/client'](this.$route.params.code)
    },
    clients () {
      if (this.isSelf) return this.$store.getters['clients/baseClients']
      return this.client.clients ? Object.values(this.client.clients) : []
    },
  },

  created () {
    moment.locale(this.$i18n.locale)
  },
  mounted () {
    this.setHeaders()
  },

  methods: {
    handleClick (client) {
      if (this.clickable) {
        this.$router.push({ name: 'Client', params: { code: client.code } })
      }
    },
    setHeaders () {
      this.headers = [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: this.$t('code'), value: 'code' },
        { text: this.$phraser.set('id number'), value: 'business_number' },
        { text: this.$phraser.set('updated at'), value: 'updated_at' },
        { text: this.$phraser.set('created at'), value: 'created_at' },
      ]
    },
    formatTime (time) {
      return moment(time)._isValid ? moment(time).format('lll') : time
    },
  },
}
</script>
